import React from "react"

import "./IframeEmbed.scss"

import Section from "../../components/section"
import SectionInner from "../../components/section-inner/section-inner"
import { connect } from "react-redux"
import { setIsUpdate } from "../../state/actions"

const IframeEmbed = ({ title, iframeUrl, subtitle, description }) => {
  return (
    <Section className="Section--vPadding">
      <SectionInner>
        {
          title &&
          <h1 className="Section-title t-h1">{title}</h1>
        }
        <div className="Section-title t-h4 u-topMarginLg">{subtitle}</div>
        <div className="Section-text t-body2 hasLinkAfter" dangerouslySetInnerHTML={{ __html: description }}/>

        <iframe src={iframeUrl}
                title={title}
                className="IframeEmbed-iframe"
                sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
                allowTransparency="true"
                width="100%"
                height="500px"
                scrolling="no" frameBorder="0"
                marginWidth="10" marginHeight="10"/>

      </SectionInner>
    </Section>
  )
}

const mapStateToProps = (state) => {
  return {
    lang: state.lang,
  }
}

const mapDispatchToProps = {
  setIsUpdate,
}

export default connect(mapStateToProps, mapDispatchToProps)(IframeEmbed)
